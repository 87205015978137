"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GitLogJSONTranslator = void 0;
var Duration_1 = require("changestream-webui/domain/model/time/Duration");
var GitLogJSONTranslator = /** @class */ (function () {
    function GitLogJSONTranslator(gitLogDTO) {
        this.gitLogDTO = gitLogDTO;
    }
    Object.defineProperty(GitLogJSONTranslator.prototype, "gitLog", {
        get: function () {
            var _this = this;
            return {
                commits: this.gitLogDTO.commits.map(function (commit) {
                    return _this.commitLogEntryFrom(commit);
                }),
            };
        },
        enumerable: false,
        configurable: true
    });
    GitLogJSONTranslator.prototype.commitLogEntryFrom = function (commitDTO) {
        return {
            authoredAt: new Date(commitDTO.authoredAt),
            duration: Duration_1.Duration.parsedFrom(commitDTO.duration),
        };
    };
    return GitLogJSONTranslator;
}());
exports.GitLogJSONTranslator = GitLogJSONTranslator;
