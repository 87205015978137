"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Route = void 0;
var PathTemplate_1 = require("changestream-webui/routing/PathTemplate");
var RouteMatch_1 = require("changestream-webui/routing/RouteMatch");
var Route = /** @class */ (function () {
    function Route() {
    }
    Route.matchingExact = function (path, value) {
        return new ExactRoute(path, value);
    };
    Route.matchingPattern = function (pattern, value) {
        return PatternRoute.matchingPattern(pattern, value);
    };
    return Route;
}());
exports.Route = Route;
var ExactRoute = /** @class */ (function (_super) {
    __extends(ExactRoute, _super);
    function ExactRoute(path, value) {
        var _this = _super.call(this) || this;
        _this.path = path;
        _this.value = value;
        return _this;
    }
    ExactRoute.prototype.matchOf = function (path) {
        return path === this.path ? RouteMatch_1.RouteMatch.found() : RouteMatch_1.RouteMatch.notFound();
    };
    return ExactRoute;
}(Route));
var PatternRoute = /** @class */ (function (_super) {
    __extends(PatternRoute, _super);
    function PatternRoute(pattern, value) {
        var _this = _super.call(this) || this;
        _this.pattern = pattern;
        _this.value = value;
        return _this;
    }
    PatternRoute.matchingPattern = function (pattern, value) {
        return new PatternRoute(PathTemplate_1.PathTemplate.of(pattern).regexp, value);
    };
    PatternRoute.prototype.matchOf = function (path) {
        var _a;
        var match = this.pattern.exec(path);
        return match
            ? RouteMatch_1.RouteMatch.foundWithPathParams((_a = match.groups) !== null && _a !== void 0 ? _a : {})
            : RouteMatch_1.RouteMatch.notFound();
    };
    return PatternRoute;
}(Route));
