"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductRef = void 0;
var ProductRef = /** @class */ (function () {
    function ProductRef(owner, name) {
        this.owner = owner;
        this.name = name;
    }
    ProductRef.of = function (owner, name) {
        return new ProductRef(owner, name);
    };
    return ProductRef;
}());
exports.ProductRef = ProductRef;
