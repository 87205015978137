"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Credentials = void 0;
var Credentials = /** @class */ (function () {
    function Credentials(username, password) {
        this.username = username;
        this.password = password;
    }
    Credentials.empty = function () {
        return new Credentials("", "");
    };
    Credentials.prototype.withUsername = function (username) {
        return new Credentials(username.toLowerCase().trim(), this.password);
    };
    Credentials.prototype.withPassword = function (password) {
        return new Credentials(this.username, password);
    };
    Object.defineProperty(Credentials.prototype, "areValid", {
        get: function () {
            return this.username !== "" && this.password !== "";
        },
        enumerable: false,
        configurable: true
    });
    return Credentials;
}());
exports.Credentials = Credentials;
