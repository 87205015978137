"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPage = void 0;
var DateTimeFormatter_1 = require("changestream-webui/ui/DateTimeFormatter");
var ProductPage = /** @class */ (function () {
    function ProductPage(productRef, gitLogGateway) {
        this.productRef = productRef;
        this.gitLogGateway = gitLogGateway;
        this.dateTimeFormatter = new DateTimeFormatter_1.DateTimeFormatter();
    }
    ProductPage.prototype.renderIn = function (container) {
        var _this = this;
        container.innerHTML = "\n      <h1>changestream: <span class=\"product-owner\">".concat(this.productRef.owner, "</span> / <span class=\"product-name\">").concat(this.productRef.name, "</span></h1>\n      <section>\n        <h2>Git log</h2>\n        <p role=\"status\">loading...</p>\n      </section>\n    ");
        var section = container.querySelector("section");
        this.gitLogGateway
            .getGitLogOf(this.productRef, {
            onGitLog: function (gitLog) { return _this.renderGitLogIn(section, gitLog); },
            onEmpty: function () { return _this.renderEmptyGitLogIn(section); },
        })
            .then(function () { })
            .catch(function () { return _this.renderGitLogLoadFailureIn(section); });
    };
    ProductPage.prototype.renderGitLogIn = function (section, gitLog) {
        this.removeStatusFrom(section);
        section.insertAdjacentHTML("beforeend", "<ul>".concat(this.commitsHTMLFrom(gitLog.commits), "</ul>"));
    };
    ProductPage.prototype.commitsHTMLFrom = function (commits) {
        var _this = this;
        return commits.map(function (it) { return _this.commitHTMLFrom(it); }).join("");
    };
    ProductPage.prototype.commitHTMLFrom = function (commit) {
        return "<li>\n      <time aria-description=\"Authored at\">".concat(this.dateTimeFormatter.formatDateTime(commit.authoredAt), "</time>\n      <time aria-description=\"Duration\" datetime=\"").concat(commit.duration.iso8601String, "\">").concat(commit.duration.humanReadable, "</time>\n    </li>");
    };
    ProductPage.prototype.renderEmptyGitLogIn = function (section) {
        this.removeStatusFrom(section);
        section.insertAdjacentHTML("beforeend", "<p>No commits yet</p>");
    };
    ProductPage.prototype.renderGitLogLoadFailureIn = function (section) {
        this.removeStatusFrom(section);
        section.insertAdjacentHTML("beforeend", "<p role='alert'>Failed to load commits</p>");
    };
    ProductPage.prototype.removeStatusFrom = function (section) {
        var _a;
        (_a = section.querySelector("[role=status]")) === null || _a === void 0 ? void 0 : _a.remove();
    };
    return ProductPage;
}());
exports.ProductPage = ProductPage;
