"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignupPage = void 0;
var Credentials_1 = require("changestream-webui/domain/model/auth/Credentials");
var SignupPage = /** @class */ (function () {
    function SignupPage(signupGateway) {
        this.signupGateway = signupGateway;
    }
    SignupPage.prototype.renderIn = function (container) {
        new SignupView(container, this.signupGateway).render();
    };
    return SignupPage;
}());
exports.SignupPage = SignupPage;
var SignupView = /** @class */ (function () {
    function SignupView(container, signupGateway) {
        this.container = container;
        this.signupGateway = signupGateway;
        this.credentials = Credentials_1.Credentials.empty();
    }
    SignupView.prototype.render = function () {
        var _this = this;
        this.container.innerHTML = "\n      <h1>changestream</h1>\n      <form class=\"signup\">\n        <h2>Sign up</h2>\n        <p>\n          <label for=\"signup-username\">Username</label>\n          <input id=\"signup-username\" type=\"text\" />\n        </p>\n        <p>\n          <label for=\"signup-password\">Password</label>\n          <input id=\"signup-password\" type=\"password\" />\n        </p>\n        <p>\n          <button type=\"submit\" disabled>Sign up</button>\n        </p>\n      </form>\n    ";
        this.onUsernameChange(function (username) {
            _this.updateCredentials(_this.credentials.withUsername(username));
        });
        this.onPasswordChange(function (password) {
            _this.updateCredentials(_this.credentials.withPassword(password));
        });
        this.onSubmit(function () {
            _this.displayLoader();
            _this.disableSubmission();
            _this.signupGateway
                .signUp(_this.credentials, {
                onSignedUp: function () { return _this.displayConfirmation(); },
                onUsernameAlreadyTaken: function () { return _this.alertAboutUsernameAlreadyTaken(); },
                onInvalidCredentials: function () { return _this.alertAboutInvalidCredentials(); },
            })
                .then(function () { })
                .catch(function () {
                _this.displayUnknownSignupFailure();
                _this.toggleSubmission();
            });
        });
    };
    SignupView.prototype.onUsernameChange = function (usernameHandler) {
        this.onInputOf(this.container.querySelector("#signup-username"), usernameHandler);
    };
    SignupView.prototype.onPasswordChange = function (passwordHandler) {
        this.onInputOf(this.container.querySelector("#signup-password"), passwordHandler);
    };
    SignupView.prototype.onInputOf = function (element, eventHandler) {
        element.addEventListener("input", function () { return eventHandler(element.value); });
    };
    SignupView.prototype.updateCredentials = function (newCredentials) {
        this.credentials = newCredentials;
        this.toggleSubmission();
    };
    SignupView.prototype.toggleSubmission = function () {
        if (this.credentials.areValid) {
            this.enableSubmission();
        }
        else {
            this.disableSubmission();
        }
    };
    SignupView.prototype.enableSubmission = function () {
        this.submitButton().disabled = false;
    };
    SignupView.prototype.disableSubmission = function () {
        this.submitButton().disabled = true;
    };
    SignupView.prototype.submitButton = function () {
        return this.container.querySelector("button[type=submit]");
    };
    SignupView.prototype.onSubmit = function (onSubmit) {
        var _a;
        (_a = this.container
            .querySelector("form")) === null || _a === void 0 ? void 0 : _a.addEventListener("submit", function (event) {
            event.preventDefault();
            onSubmit();
        });
    };
    SignupView.prototype.displayLoader = function () {
        this.displayStatus("Signing up...");
    };
    SignupView.prototype.displayConfirmation = function () {
        this.displayStatus("You have signed up successfully!");
    };
    SignupView.prototype.displayStatus = function (status) {
        this.displayStatusHtml("<p role=\"status\">".concat(status, "</p>"));
    };
    SignupView.prototype.alertAboutUsernameAlreadyTaken = function () {
        this.displaySignupFailure("This username has already been taken");
    };
    SignupView.prototype.alertAboutInvalidCredentials = function () {
        this.displaySignupFailure("Invalid credentials");
    };
    SignupView.prototype.displayUnknownSignupFailure = function () {
        this.displaySignupFailure("Failed to sign up");
    };
    SignupView.prototype.displaySignupFailure = function (failureMessage) {
        this.displayStatusHtml("<p role=\"alert\">".concat(failureMessage, "</p>"));
    };
    SignupView.prototype.displayStatusHtml = function (html) {
        this.removeStatus();
        this.container.querySelector("h2").insertAdjacentHTML("afterend", html);
    };
    SignupView.prototype.removeStatus = function () {
        var _a, _b;
        (_a = this.container.querySelector("p[role=status]")) === null || _a === void 0 ? void 0 : _a.remove();
        (_b = this.container.querySelector("p[role=alert]")) === null || _b === void 0 ? void 0 : _b.remove();
    };
    return SignupView;
}());
