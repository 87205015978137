"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageNotFound = void 0;
var PageNotFound = /** @class */ (function () {
    function PageNotFound() {
    }
    PageNotFound.prototype.renderIn = function (container) {
        container.innerHTML = "\n      <h1>changestream</h1>\n      <section>\n        <h2>Page not found</h2>\n      </section>\n    ";
    };
    return PageNotFound;
}());
exports.PageNotFound = PageNotFound;
