"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathTemplate = void 0;
var PathTemplate = /** @class */ (function () {
    function PathTemplate(template) {
        this.template = template;
    }
    PathTemplate.of = function (template) {
        return new PathTemplate(template);
    };
    Object.defineProperty(PathTemplate.prototype, "regexp", {
        get: function () {
            return new RegExp("^" + this.template.replace(/:(\w+)/g, "(?<$1>[^/]+)") + "$");
        },
        enumerable: false,
        configurable: true
    });
    return PathTemplate;
}());
exports.PathTemplate = PathTemplate;
