"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Router = void 0;
var Route_1 = require("changestream-webui/routing/Route");
var Router = /** @class */ (function () {
    function Router() {
        this.routes = [];
    }
    Router.prototype.routeExactly = function (path, value) {
        this.routes.push(Route_1.Route.matchingExact(path, value));
        return this;
    };
    Router.prototype.routePattern = function (pattern, value) {
        this.routes.push(Route_1.Route.matchingPattern(pattern, value));
        return this;
    };
    Router.prototype.resolve = function (path, onMatch, onMismatch) {
        for (var _i = 0, _a = this.routes; _i < _a.length; _i++) {
            var route = _a[_i];
            var match = route.matchOf(path);
            if (match.matched) {
                onMatch(route.value(match.pathParams));
                return;
            }
        }
        onMismatch();
    };
    return Router;
}());
exports.Router = Router;
