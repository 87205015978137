"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteMatch = void 0;
var RouteMatch = /** @class */ (function () {
    function RouteMatch(matched, pathParams) {
        this.matched = matched;
        this.pathParams = pathParams;
    }
    RouteMatch.notFound = function () {
        return new RouteMatch(false, {});
    };
    RouteMatch.found = function () {
        return this.foundWithPathParams({});
    };
    RouteMatch.foundWithPathParams = function (params) {
        return new RouteMatch(true, params);
    };
    return RouteMatch;
}());
exports.RouteMatch = RouteMatch;
