"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var App_1 = require("changestream-webui/App");
var PageNotFound_1 = require("changestream-webui/ui/PageNotFound");
var HomePage_1 = require("changestream-webui/ui/HomePage");
var ProductPage_1 = require("changestream-webui/ui/ProductPage");
var ChangestreamHttpGateway_1 = require("changestream-webui/gateways/http/ChangestreamHttpGateway");
require("css/main.css");
var SignupPage_1 = require("changestream-webui/ui/SignupPage");
var gateway = new ChangestreamHttpGateway_1.ChangestreamHttpGateway("/api");
new App_1.App({ path: window.location.pathname }, new PageNotFound_1.PageNotFound(), new HomePage_1.HomePage(), new SignupPage_1.SignupPage(gateway), function (productRef) { return new ProductPage_1.ProductPage(productRef, gateway); }).renderIn(document.getElementById("root"));
