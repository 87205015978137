"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Duration = void 0;
var iso8601RegExp = new RegExp("^PT((?<hours>\\d+)H)?((?<minutes>\\d+)M)?((?<seconds>\\d+)S)?$");
var Duration = /** @class */ (function () {
    function Duration(iso8601String, seconds, minutes, hours) {
        this.iso8601String = iso8601String;
        this.seconds = seconds;
        this.minutes = minutes;
        this.hours = hours;
    }
    Duration.parsedFrom = function (iso8601String) {
        var match = iso8601RegExp.exec(iso8601String);
        return new Duration(iso8601String, this.numberNamed("seconds", match), this.numberNamed("minutes", match), this.numberNamed("hours", match));
    };
    Duration.numberNamed = function (name, match) {
        return match ? match.groups[name] : 0;
    };
    Object.defineProperty(Duration.prototype, "humanReadable", {
        get: function () {
            var parts = [];
            if (this.hours > 0) {
                parts.push("".concat(this.hours, "h"));
            }
            if (this.minutes > 0) {
                parts.push("".concat(this.minutes, "m"));
            }
            if (this.seconds > 0 || parts.length === 0) {
                parts.push("".concat(this.seconds, "s"));
            }
            return parts.join(" ");
        },
        enumerable: false,
        configurable: true
    });
    return Duration;
}());
exports.Duration = Duration;
