"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
var Router_1 = require("changestream-webui/routing/Router");
var ProductRef_1 = require("changestream-webui/domain/model/ProductRef");
var App = /** @class */ (function () {
    function App(location, pageNotFoundComponent, homePageComponent, signupPageComponent, productPageComponent) {
        var _this = this;
        this.location = location;
        this.pageNotFoundComponent = pageNotFoundComponent;
        this.homePageComponent = homePageComponent;
        this.signupPageComponent = signupPageComponent;
        this.productPageComponent = productPageComponent;
        this.router = new Router_1.Router()
            .routeExactly("/", function () { return _this.homePageComponent; })
            .routeExactly("/signup", function () { return _this.signupPageComponent; })
            .routePattern("/:owner/:productName", function (_a) {
            var owner = _a.owner, productName = _a.productName;
            return _this.productPageComponent(ProductRef_1.ProductRef.of(owner, productName));
        });
    }
    App.prototype.renderIn = function (container) {
        var _this = this;
        this.router.resolve(this.location.path, function (component) { return component.renderIn(container); }, function () { return _this.pageNotFoundComponent.renderIn(container); });
    };
    return App;
}());
exports.App = App;
