"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = void 0;
var HomePage = /** @class */ (function () {
    function HomePage() {
    }
    HomePage.prototype.renderIn = function (container) {
        container.innerHTML = "<h1>changestream</h1>";
    };
    return HomePage;
}());
exports.HomePage = HomePage;
