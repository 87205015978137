"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTimeFormatter = void 0;
var DateTimeFormatter = /** @class */ (function () {
    function DateTimeFormatter() {
    }
    DateTimeFormatter.prototype.formatDateTime = function (date) {
        return date.toISOString().replace("T", " ").substring(0, 19);
    };
    return DateTimeFormatter;
}());
exports.DateTimeFormatter = DateTimeFormatter;
